// looks for a checkbox with data-checkbox-trigger attribute.
// when this checkbox is checked or unchecked, all other checkedboxes with a
// data-checkbox-triggered attribute are also checked or unchecked.
window.addEventListener('load', function () {
  var trigger = document.querySelector('[data-checkbox-trigger]');
  if (trigger) {
    var triggered = document.querySelectorAll('[data-checkbox-triggered]');
    trigger.addEventListener('change', function () {
      for (var i in triggered) {
        triggered[i].checked = trigger.checked;
      }
    });
  }
});
